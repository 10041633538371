import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Recoil
import { RecoilRoot } from "recoil";

// CSS
import "./index.css";
import "./output.css";
import "./custom.css";

// Views
import Register from "./views/Register";
import LoginView from "./views/LoginView";
import Dashboard from "./views/Dashboard";
import PageNotFound from "./views/NotFound";
import ResetPassword from "./views/ResetPassword";
import SetNewPassword from "./views/SetNewPassword";
import Verify from "./views/VerifyAccount";
import Account from "./views/Account";
import Assets from "./views/Assets";

const App = () => {
  return (
    <RecoilRoot>
      <Router>
        <Routes>
          <Route path="/" element={<Register />} />
          <Route path="/register" element={<Register />} />
          <Route path="/log-in" element={<LoginView />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="*" element={<PageNotFound />} />
          {/* Protect the routes from here on using withAuth()*/}
          <Route path="/account" element={<Account />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/assets" element={<Assets />} />
        </Routes>
      </Router>
    </RecoilRoot>
  );
};

export default App;

// Uses node v18.14.0
