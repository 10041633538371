import React from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "./cookie";

// AN HOC that wraps around any component that needs to be protected

const withAuth = (WrappedComponent) => {
  return (props) => {
    const authExpiry = getCookie("auth-expiry");
    const isExpired = authExpiry ? Number(authExpiry) < Date.now() : true;
    console.log("isExpired", isExpired, authExpiry);

    if (!isExpired) {
      return <WrappedComponent {...props} />;
    } else {
      return <Navigate to="/log-in" />;
    }
  };
};

export default withAuth;
