import React, { memo } from "react";

const ShowError = memo(({ error }) => {
  return (
    <div
      className={`transition-opacity duration-500 ${
        error ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="text-red-500 text-center">{error}</div>
    </div>
  );
});

export default ShowError;
