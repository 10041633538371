import React from "react";
import { BounceLoader } from "react-spinners";

const Loading = ({ size }) => {
  return (
    <div className="flex items-center justify-center">
      <BounceLoader color="#3B82F6" size={size ?? "150px"} />
    </div>
  );
};

export default Loading;
