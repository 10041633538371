import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import RegLayout from "../layouts/LoginEtc";
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonText from "../components/ButtonText";
import { resetPassword } from "../api/auth";
import { validateEmail, validatePassword } from "../utils/validation";
import Loading from "../components/Loading";
import {
  EMPTY_FIELDS,
  INVALID_EMAIL,
  INVALID_PASSWORD,
} from "../constants/errorMessages";
import { userState } from "../recoil";

const ResetPassword = () => {
  const user = useRecoilState(userState);
  const navigate = useNavigate();
  const [email, setEmail] = useState(user?.email || "");
  const [error, setError] = useState("");
  const [passwordRequestSent, setPasswordRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError("");
  };

  const handleForgotPassword = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (email === "") {
      setError(EMPTY_FIELDS);
      return;
    }
    if (!validateEmail(email)) {
      setError(INVALID_EMAIL);
      return;
    }
    try {
      await resetPassword(email);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setLoading(false);
      setPasswordRequestSent(true);
    }
  };

  return (
    <RegLayout
      mainContent={
        passwordRequestSent ? (
          <div className="flex flex-col items-center">
            <h1 className="mb-4 text-center text-2xl font-bold text-blue-500">
              Request Sent
            </h1>
            <div className="mt-8">
              Please check your email for a link. You may close this tab.
            </div>
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <h1 className="mb-8 text-center text-2xl font-bold text-blue-500">
              Reset Password
            </h1>
            <div className="flex flex-col items-center">
              <form className="w-full max-w-sm" onSubmit={handleForgotPassword}>
                <Input
                  id="email"
                  type="email"
                  autoComplete={"email"}
                  placeholder={"Email"}
                  label={email ? "Email" : ""}
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="text-center text-red-500">{error}</div>
                <div className="mb-8 mt-12 text-center">
                  <Button name="Reset" type="submit" disabled={email === ""} />
                </div>
              </form>

              <div className="mt-8">
                <ButtonText
                  text="Not registered?"
                  onClick={() => navigate("/register")}
                />
              </div>
            </div>
          </>
        )
      }
    />
  );
};

export default ResetPassword;
