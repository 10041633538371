const Input = ({
  label,
  id,
  name,
  placeholder,
  value,
  onChange,
  type,
  autoComplete,
  disabled,
}) => {
  return (
    <div className="relative my-4 w-full flex-1">
      <input
        className="h-10 w-full border-b-2 border-gray-300 text-gray-900 transition-all duration-500 focus:outline-none"
        id={id}
        name={name}
        autoComplete={autoComplete}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className="absolute -top-3.5 left-0 text-sm text-gray-400 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-xs peer-focus:text-gray-600 "
      >
        {label}
      </label>
    </div>
  );
};

export default Input;
