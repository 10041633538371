import React from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";
import { userState } from "../recoil";

import { logout } from "../api/auth";

import Button from "../components/Button";
import MainLayout from "../layouts/Main";

import { dateFormatter } from "../utils/manipulation";

const Account = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const { firstName, lastName, email, lastLogin } = user;
  const lastLoginDate = lastLogin
    ? dateFormatter.format(new Date(lastLogin))
    : "";

  const handleLogout = async () => {
    console.log("Logging out");
    try {
      await logout();
      console.log("Logged out");
      setUser({});
      navigate("/log-in");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainLayout
      useFooter={false}
      mainContent={
        <>
          <h1 className="pt-8 text-center text-3xl">Account Information</h1>
          <div className="m-8 flex flex-col space-y-4 rounded-lg bg-gray-100 p-8 text-left shadow-lg">
            <div>
              {firstName} {lastName}
            </div>
            <div>{email}</div>
            <div>Last logged in: {lastLoginDate && lastLoginDate}</div>
            <Button name="Logout" onClick={handleLogout} />
          </div>
        </>
      }
    />
  );
};

export default Account;
