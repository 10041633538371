import axios from "axios";
import { BASE_URL } from "./config";

// We register with password, and then confirm with code via email
export const register = async (first_name, last_name, email, password) => {
  console.log("register");
  const response = await axios.post(`${BASE_URL}/auth/register`, {
    first_name,
    last_name,
    email,
    password,
  });
  return response.data;
};

export const confirmAccount = async (email, code) => {
  const response = await axios.post(
    `${BASE_URL}/auth/confirm`,
    {
      email,
      code,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const resendConfirmationCode = async (email) => {
  const response = await axios.post(`${BASE_URL}/auth/resend`, {
    email,
  });
  return response.data;
};

export const login = async (email, password) => {
  console.log("login");
  const response = await axios.post(
    `${BASE_URL}/auth/login`,
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const resetPassword = async (email) => {
  const response = await axios.post(`${BASE_URL}/auth/request-reset`, {
    email,
  });
  return response.data;
};

export const setNewPassword = async (token, new_password) => {
  const response = await axios.post(`${BASE_URL}/auth/confirm-reset`, {
    token,
    new_password,
  });
  return response.data;
};

export const logout = async () => {
  console.log("logout called");
  const response = await axios.post(`${BASE_URL}/auth/logout`);
  return response.data;
};

export const google = async (token) => {
  console.log("google");
  const response = await axios.post(`${BASE_URL}/auth/google`, {
    token,
  });
  return response.data;
};
