import axios from "axios";
import { BASE_URL } from "./config";

export const addAsset = async (asset) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/upload/upload`,
      { name: asset },
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred while adding the asset:", error);
  }
};

export const getManufacturer = async (str) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/search/find-manufacturer?name=${str}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error("An error occurred while getting the manufacturer:", error);
  }
};
