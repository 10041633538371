import React from "react";
import withAuth from "../utils/withAuth";
import MainLayout from "../layouts/Main";
import AddAsset from "../components/AddAsset";
import Button from "../components/Button";

const Assets = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <MainLayout
      buttonBar={
        <div className="flex-1 text-right">
          <Button name="Add Asset" onClick={() => setShowModal(true)} />
        </div>
      }
      mainContent={
        <div>
          Assets
          {showModal && <AddAsset showModal={setShowModal} />}
        </div>
      }
    />
  );
};

export default withAuth(Assets);
