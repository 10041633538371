import React from "react";

const Modal = ({ mainContent, title, showModal, ...restProps }) => {
  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
      <div
        className="flex w-11/12 flex-col rounded-lg bg-white p-8 shadow-lg md:w-1/2 lg:w-1/3"
        {...restProps}
      >
        <div className="w-full pb-4 text-left text-xl">{title || "Title"}</div>
        <div className="flex-1 text-center">
          {mainContent || "Some content"}
        </div>
      </div>
    </div>
  );
};

export default Modal;
