import React from "react";
import NavBar from "../components/NavBar";

const MainLayout = ({ mainContent, buttonBar, header, useFooter }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-gray-500 text-white p-4 sticky top-0 z-40">
        <NavBar />
      </header>
      {buttonBar && (
        <div className="bg-white flex text-black p-4 sticky top-16 z-30">
          {buttonBar}
        </div>
      )}
      {header && (
        <div className="bg-gray-500 text-white p-4 sticky top-32 z-20">
          {header}
        </div>
      )}
      <main className="flex-grow relative p-4">{mainContent}</main>
      {useFooter && (
        <footer className="bg-green-500 text-white p-4 sticky bottom-0 z-10">
          Footer
        </footer>
      )}
    </div>
  );
};

export default MainLayout;
