import React from "react";

const FooterReg = () => {
  return (
    <div className="fixed bottom-0 flex p-1 justify-center items-center">
      <span className="text-sm text-gray-500">Copyright 2023 Perdix Ltd</span>
      <span className="text-sm text-gray-500 mx-2">|</span>
      <span className="text-sm text-gray-500">
        <a href="">Privacy Policy</a>
      </span>
      <span className="text-sm text-gray-500 mx-2">|</span>
      <span className="text-sm text-gray-500">
        <a href="">Terms of Service</a>
      </span>
    </div>
  );
};

export default FooterReg;
