import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { navLinks } from "../constants/navigations";
import { useRecoilState } from "recoil";
import { userState } from "../recoil";
import { useIsActive } from "../hooks/useIsActive";
import { capitalizeWords } from "../utils/manipulation";

const linkStyle =
  "px-3 py-2 flex items-center leading-snug text-white hover:opacity-75";
const activeStyle =
  "px-3 py-2 flex items-center leading-snug text-white hover:opacity-75 underline";

const DropdownMenu = ({ name, isOpen, onClick }) => (
  <div
    className={`dropdown-content absolute left-0 mt-2 px-4 rounded-md shadow-lg bg-gray-500 ring-1 ring-black ring-opacity-5 overflow-hidden whitespace-nowrap ${
      isOpen ? "block" : "hidden"
    }`}
  >
    {navLinks[name].map((link, index) => {
      const isActive = useIsActive(link.path); // Check if active
      return (
        <NavLink
          key={index}
          to={link.path}
          className={`${linkStyle} ${isActive ? activeStyle : linkStyle}`}
          onClick={onClick}
        >
          {link.label}
        </NavLink>
      );
    })}
  </div>
);

const NavBar = () => {
  const [user] = useRecoilState(userState);
  const [isLoggedIn] = useState(true); // Assuming user state will be used in real code
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const handleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="relative flex flex-wrap items-center justify-between py-3">
      <ul
        className="flex flex-col lg:flex-row list-none ml-auto"
        ref={dropdownRef}
      >
        {Object.keys(navLinks).map((key) => (
          <li className="nav-item dropdown relative cursor-pointer" key={key}>
            <button onClick={() => handleDropdown(key)} className={linkStyle}>
              {capitalizeWords(key)}
            </button>
            <DropdownMenu
              name={key}
              isOpen={openDropdown === key}
              onClick={null}
            />
          </li>
        ))}
        <li className="nav-item">
          <NavLink className={linkStyle} to="/account">
            {isLoggedIn ? `Hi ${user?.firstName || "User"}` : "Log in"}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
