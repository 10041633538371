import React from "react";
import { TEXT_BUTTON_COLOR } from "../constants/styles";

// Default colour is in constants/styles.js. To override it pass in a color prop

const ButtonText = ({ text, onClick, textColour }) => {
  const textColorClass = textColour
    ? `text-${textColour}-500`
    : `text-${TEXT_BUTTON_COLOR}-500`;
  const hoverColorClass = textColour
    ? `hover:text-${textColour}-700`
    : `hover:text-${TEXT_BUTTON_COLOR}-700`;

  return (
    <button
      className={`text-sm ${textColorClass} ${hoverColorClass}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default ButtonText;
