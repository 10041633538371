import React from "react";
import FooterReg from "../components/FooterReg";

const RegLayout = ({ mainContent }) => {
  return (
    <div className="h-screen">
      <div className="flex h-16 w-full items-center justify-center bg-blue-500 text-2xl text-white">
        Box and Papers
      </div>
      <div className="flex items-center justify-center">
        <div className="mt-16 w-5/6 rounded-lg p-12 text-center shadow-lg md:w-1/2 lg:w-1/3">
          {mainContent}
        </div>
      </div>
      <FooterReg />
    </div>
  );
};

export default RegLayout;
