import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useRecoilState } from "recoil";
import RegLayout from "../layouts/LoginEtc";
import ShowError from "../components/ShowError";
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonText from "../components/ButtonText";
import { login } from "../api/auth";
import { validateEmail, validatePassword } from "../utils/validation";
import {
  EMPTY_FIELDS,
  INVALID_EMAIL,
  INVALID_PASSWORD,
} from "../constants/errorMessages";
import { userState } from "../recoil";

const LoginView = () => {
  const [, setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("fade-entered");

  const handleLogin = async (event) => {
    event.preventDefault();
    if (email === "" || password === "") {
      setError(EMPTY_FIELDS);
      return;
    }
    if (!validateEmail(email)) {
      setError(INVALID_EMAIL);
      return;
    }
    // Turned off for testing
    // if (!validatePassword(password)) {
    //   setError(INVALID_PASSWORD);
    //   return;
    // }

    try {
      const result = await login(email, password);
      const user = {
        email: result?.email,
        firstName: result?.first_name,
        lastName: result?.last_name,
        lastLogin: result?.last_login,
      };
      setUser(user);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleNext = (event) => {
    event.preventDefault();
    setCurrentClass("fade-exiting");
    setTimeout(() => {
      if (!email) {
        setError(EMPTY_FIELDS);
        setCurrentClass("fade-entered");
        return;
      }
      setStep(2);
      setCurrentClass("fade-entered");
    }, 300);
  };

  return (
    <RegLayout
      mainContent={
        <>
          <div className={step === 2 ? "block" : "hidden"}>
            <div className="-ml-6 -mt-8 mb-12 w-full text-left">
              <ButtonText
                text="Back"
                textColour="gray"
                onClick={() => setStep(1)}
              />
            </div>
          </div>

          <div
            className={`flex flex-col items-center transition-all ${currentClass}`}
          >
            <div className={step === 1 ? "block" : "hidden"}>
              <h1 className="mb-8 text-center text-2xl font-bold text-blue-500">
                Login
              </h1>
            </div>
            <form className="w-full max-w-sm">
              <div className={step === 1 ? "block" : "hidden"}>
                <Input
                  id="email"
                  type="email"
                  autoComplete={"email"}
                  placeholder={"Email"}
                  label={email ? "Email" : ""}
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className={step === 2 ? "block" : "hidden"}>
                <Input
                  id="new-password"
                  type="password"
                  autoComplete={"new-password"}
                  placeholder={"Password"}
                  label={password ? "Password" : ""}
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <ShowError error={error} />
              <div className={step === 1 ? "block" : "hidden"}>
                <div className="mb-8 mt-12 text-center">
                  <Button name="Next" onClick={handleNext} />
                </div>
                <ButtonText
                  text="Sign Up"
                  onClick={() => {
                    navigate("/register");
                  }}
                />
              </div>
              <div className={step === 2 ? "block" : "hidden"}>
                <div className="mb-8 mt-12 text-center">
                  <Button name="Log In" type="submit" onClick={handleLogin} />
                </div>
                <ButtonText
                  text="Forgot password?"
                  onClick={() => {
                    navigate("/reset-password");
                  }}
                />
              </div>
            </form>
          </div>
        </>
      }
    />
  );
};

export default LoginView;
