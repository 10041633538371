import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { confirmAccount } from "../api/auth";
import Loading from "../components/Loading";
import { getCookie } from "../utils/cookie";
import { useRecoilState } from "recoil";
import { userState } from "../recoil";

const Verify = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(queryParams.get("email"));
  const code = decodeURIComponent(queryParams.get("code"));
  const [, setUser] = useRecoilState(userState);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const result = await confirmAccount(email, code);
        setUser({
          email: result?.email,
          firstName: result?.first_name,
          lastName: result?.last_name,
          lastLogin: result?.last_login,
        });

        const checkForUpdatedCookie = () => {
          const authExpiry = getCookie("auth-expiry");
          if (authExpiry && Number(authExpiry) > Date.now()) {
            navigate("/dashboard");
            return;
          }
          setTimeout(checkForUpdatedCookie, 100);
        };

        checkForUpdatedCookie();
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    if (email && code) {
      verifyEmail();
    } else {
      setIsLoading(false);
    }
  }, [email, code, navigate]);

  return (
    <div className="flex h-screen items-center justify-center">
      {isLoading ? (
        <Loading />
      ) : (
        <p className="text-center">Failed to verify email. Please try again.</p>
      )}
    </div>
  );
};

export default Verify;
