import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import RegLayout from "../layouts/LoginEtc";
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonText from "../components/ButtonText";
import { setNewPassword } from "../api/auth";
import { validatePassword } from "../utils/validation";
import { EMPTY_FIELDS, INVALID_PASSWORD } from "../constants/errorMessages";
import { userState } from "../recoil";

const LoginView = () => {
  const navigate = useNavigate();
  const [, setUser] = useRecoilState(userState);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const token = decodeURIComponent(queryParams.get("token"));

  const handleSetPassword = async (event) => {
    event.preventDefault();
    setUser({});
    if (password === "" || confirmPassword === "") {
      setError(EMPTY_FIELDS);
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    // Turned off for testing
    // if (!validatePassword(password)) {
    //   setError(INVALID_PASSWORD);
    //   return;
    // }

    try {
      const result = await setNewPassword(token, password);
      const user = {
        email: result?.email,
        firstName: result?.first_name,
        lastName: result?.last_name,
        lastLogin: result?.last_login,
      };
      setUser(user);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
  };

  return (
    <RegLayout
      mainContent={
        <>
          <h1 className="mb-8 text-center text-2xl font-bold text-blue-500">
            Set a New Password
          </h1>
          <div className="flex flex-col items-center">
            <form className="w-full max-w-sm" onSubmit={handleSetPassword}>
              <Input
                id="password"
                type="password"
                autoComplete={"current-password"}
                placeholder={"Password"}
                label={password ? "Password" : ""}
                value={password}
                onChange={handlePasswordChange}
              />
              <Input
                id="confirm-password"
                type="password"
                autoComplete={"current-password"}
                placeholder={"Confirm Password"}
                label={confirmPassword ? "Confirm Password" : ""}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <div className="text-center text-red-500">{error}</div>
              <div className="mb-8 mt-8 text-center">
                <Button
                  name="Submit"
                  type="submit"
                  disabled={password === "" || confirmPassword === ""}
                />
              </div>
            </form>
            <div className="mt-8">
              <ButtonText
                text="Not registered?"
                onClick={() => navigate("/register")}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default LoginView;
