import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const userState = atom({
  key: "userState",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const registerState = atom({
  key: "registerState",
  default: {},
});
