import React from "react";
import { BUTTON_COLOR } from "../constants/styles";

// Default colour - BUTTON_COLOR - is in constants/styles.js, if you want to override it, pass in a color prop

const Button = ({ name, onClick, disabled, color }) => {
  const baseClass =
    "text-white font-bold py-2 px-4 rounded cursor-pointer shadow-lg";
  const colorClass = color
    ? `bg-${color}-500 hover:bg-${color}-700`
    : `bg-${BUTTON_COLOR}-500 hover:bg-${BUTTON_COLOR}-700`;

  return (
    <button
      className={`${baseClass} ${colorClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

export default Button;
