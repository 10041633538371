import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

import { addAsset } from "../api/assets";

import { BASE_URL } from "../api/config";

import Modal from "../layouts/Modal";
import Button from "./Button";
import ButtonText from "./ButtonText";
import Input from "./Input";
import Loading from "./Loading";

const socket = io(BASE_URL);

const AddAsset = ({ showModal }) => {
  const [asset, setAsset] = useState({ name: "", manufacturer: "" });
  const { name, manufacturer } = asset;
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isManufacturerSelected, setIsManufacturerSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sector, setSector] = useState("");
  const [error, setError] = useState("");
  const [searchType, setSearchType] = useState("db");
  const [usingAI, setUsingAI] = useState(false);

  const handleAssetProperties = (e) => {
    setAsset({ ...asset, [e.target.name]: e.target.value });
  };

  const handleAddAsset = async () => {
    try {
      await addAsset(asset);
      showModal(false);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (!isManufacturerSelected && search.length > 0) {
      socket.on("searchResult", (result) => {
        setLoading(false);
        if (result.success) {
          setSearchResults(result.data);
        }
      });

      socket.on("searchStatus", (status) => {
        if (status.message === "Not in database") {
          setUsingAI(true);
          setSearchType("ai");
        }
      });
    }
    return () => {
      socket.off("searchResult");
      socket.off("searchStatus");
    };
  }, [search, searchType]);

  const handleSearch = () => {
    if (!sector) {
      setError("Please enter a sector");
      return;
    }
    socket.emit("searchForManufacturer", search, sector, searchType);
  };

  useEffect(() => {
    if (search.length > 0 && !isManufacturerSelected) {
      setShowResults(true);
      handleSearch();
    } else {
      setShowResults(false);
    }
  }, [search]);

  const selectManufacturer = (result) => {
    setAsset({ ...asset, manufacturer: result.name });
    setUsingAI(false);
    setSearch(result.name);
    setShowResults(false);
    setIsManufacturerSelected(true);
  };

  return (
    <div>
      {showModal && (
        <Modal
          title={"Add Asset"}
          mainContent={
            <div className="flex w-full flex-col">
              <Input
                id="sector"
                name="sector"
                type="text"
                placeholder={"Sector (e.g. Watches, Forestry, etc.)"}
                autoComplete={"none"}
                label={sector ? "Sector" : ""}
                value={sector}
                onChange={(e) => {
                  setError("");
                  setSearch("");
                  setSector(e.target.value);
                }}
              />
              <Input
                id="asset-manufacturer"
                type="text"
                name="manufacturer"
                placeholder={"Manufacturer"}
                disabled={error ? true : false}
                autoComplete={"none"}
                label={search ? "Manufacturer" : ""}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {error && <div className="text-red-500">{error}</div>}
              <Input
                id="asset-name"
                name="name"
                type="text"
                placeholder={"Asset Name"}
                autoComplete={"none"}
                label={name ? "Asset Name" : ""}
                value={name}
                onChange={(e) => {
                  handleAssetProperties(e);
                }}
              />
              <div>
                {showResults && searchResults && !loading
                  ? searchResults.map((result, index) => (
                      <div
                        key={index}
                        className="items-left mb-4 flex w-full flex-col py-1"
                        onClick={() => {
                          selectManufacturer(result);
                        }}
                      >
                        <div className="flex flex-row">
                          <div className="w-1/2 text-left">{result.name}</div>
                          <div className="w-1/2 text-right"></div>
                        </div>
                      </div>
                    ))
                  : loading && (
                      <div className="my-2 mb-4 flex justify-center">
                        <Loading size="35px" />
                      </div>
                    )}
                {search.length > 2 && usingAI && searchResults.length === 0 && (
                  <>
                    <div className="mb-4">Searching using AI</div>
                    <div className="my-2 mb-4 flex justify-center">
                      <Loading size="35px" />
                    </div>
                  </>
                )}
              </div>

              <Button
                name="Add Asset"
                onClick={() => {
                  handleAddAsset();
                }}
              />
              <div className="mt-8 flex-1">
                <ButtonText onClick={() => showModal(false)} text="Close" />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default AddAsset;
