import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

const PageNotFound = () => {
  return (
    <div className="flex h-screen items-center justify-center text-xl">
      <div className="text-center">
        <h1 className="text-red-500">404</h1>
        <p>Page not found</p>
        <Link to="/log-in">
          <button className="mt-4 rounded bg-gray-500 p-2">
            Back to Login
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
