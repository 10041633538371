import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";

// Constants
import { EMPTY_FIELDS } from "../constants/errorMessages";

// Components
import Input from "../components/Input";
import Button from "../components/Button";
import ButtonText from "../components/ButtonText";
import ShowError from "../components/ShowError";
import Loading from "../components/Loading";

// Validation
import { validateEmail } from "../utils/validation";

// API
import { register } from "../api/auth";

// Layouts and state management
import RegLayout from "../layouts/LoginEtc";
import { registerState } from "../recoil";

const Register = () => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(registerState);
  const { firstName, lastName, email, password } = user;
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("fade-entered");
  const [loading, setLoading] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const handleFirstNameChange = (event) => {
    setUser({ ...user, firstName: event.target.value });
    setError("");
  };

  const handleLastNameChange = (event) => {
    setUser({ ...user, lastName: event.target.value });
    setError("");
  };

  const handleEmailChange = (event) => {
    setUser({ ...user, email: event.target.value });
    setError("");
  };

  const handlePasswordChange = (event) => {
    setUser({ ...user, password: event.target.value });
    setError("");
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  const handleNext = (event) => {
    event.preventDefault();
    setCurrentClass("fade-exiting");
    setTimeout(() => {
      if (!firstName || !lastName) {
        setError(EMPTY_FIELDS);
        setCurrentClass("fade-entered");
        return;
      }
      setStep(2);
      setCurrentClass("fade-entered");
    }, 300);
  };

  const handleRegister = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!email || !password) {
      setError(EMPTY_FIELDS);
      return;
    }
    if (!validateEmail(email)) {
      setError(INVALID_EMAIL);
      return;
    }
    try {
      const response = await register(firstName, lastName, email, password);
      console.log(response);
      setLoading(false);
      setIsRegistered(true);
    } catch (error) {
      setLoading(false);
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <RegLayout
      mainContent={
        isRegistered ? (
          <div className="flex flex-col items-center">
            <h1 className="mb-4 text-center text-2xl font-bold text-blue-500">
              Registration Successful
            </h1>
            <div className="mt-8">
              Please check your email for a confirmation code.
            </div>
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <div className={step === 2 ? "block" : "hidden"}>
              <div className="-ml-6 -mt-8 mb-12 w-full text-left">
                <ButtonText
                  text="Back"
                  textColour="gray"
                  onClick={() => setStep(1)}
                />
              </div>
            </div>

            <div
              className={`flex flex-col items-center transition-all ${currentClass}`}
            >
              <div className={step === 1 ? "block" : "hidden"}>
                <h1 className="mb-8 text-center text-2xl font-bold text-blue-500">
                  Register
                </h1>
              </div>
              <form className="w-full max-w-sm">
                <div className={step === 1 ? "block" : "hidden"}>
                  <Input
                    id="first-name"
                    type="text"
                    autoComplete={"given-name"}
                    placeholder={"First Name"}
                    label={firstName ? "First Name" : ""}
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                  <Input
                    id="last-name"
                    type="text"
                    autoComplete={"family-name"}
                    placeholder={"Last Name"}
                    label={lastName ? "Last Name" : ""}
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                  {/* <Input
                    id="company-name"
                    type="text"
                    autoComplete={"company-name"}
                    placeholder={"Company Name (Optional)"}
                    label={organisation ? "Company Name" : ""}
                    value={organisation}
                    onChange={handleCompanyNameChange}
                  /> */}
                </div>
                <div className={step === 2 ? "block" : "hidden"}>
                  <Input
                    id="email"
                    type="email"
                    autoComplete={"email"}
                    placeholder={"Email"}
                    label={email ? "Email" : ""}
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <Input
                    id="new-password"
                    type="password"
                    autoComplete={"new-password"}
                    placeholder={"Password"}
                    label={password ? "Password" : ""}
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
                <ShowError error={error} />
                <div className={step === 1 ? "block" : "hidden"}>
                  <div className="mb-8 mt-12 text-center">
                    <Button name="Next" onClick={handleNext} />
                  </div>
                </div>
                <div className={step === 2 ? "block" : "hidden"}>
                  <div className="mb-8 mt-12 text-center">
                    <Button
                      name="Register"
                      type="submit"
                      onClick={handleRegister}
                    />
                  </div>
                </div>
              </form>
              <div className={step === 1 ? "block" : "hidden"}>
                <ButtonText
                  text="Already have an account? Log in here."
                  onClick={() => {
                    navigate("/log-in");
                  }}
                />
              </div>
            </div>
          </>
        )
      }
    />
  );
};

export default Register;
